// Conveniently import this file anywhere to use db
import {config, listConfig} from '@src/config';
import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/functions'
// import 'firebase/storage' // Use lazy loading, on include common/FileUtils.js firebase/storage will be load
// import 'firebase/performance';
// import 'firebase/analytics';


//
// <!-- Load the Firebase SDKs before loading this file -->
// <script src="/__/firebase/init.js"></script>
//
// fetch('/__/firebase/init.json').then(async response => {
//   firebase.initializeApp(await response.json());
// });


firebase.initializeApp(config.firebase);

// firebase.firestore().settings({cacheSizeBytes: 3e+8});
// firebase.firestore().enablePersistence({synchronizeTabs: true}).catch((err)=>{
//   console.error('Firestore, Erro ao habilitar Persistência, pode ser que tenha outra aba aberta do sistema', err);
// });

// firebase.firestore().enablePersistence({experimentalTabSynchronization: true});
// firebase.firestore().enablePersistence({synchronizeTabs: true});
// firebase.analytics();

if(window) {
  window.firebaseDebug = firebase;
}

// window.firebaseDatabaseOld = firebase.initializeApp({
//   databaseURL: "https://musicvoxtomold-db160.firebaseio.com/"
// }, 'firebaseDatabaseOld');

// Debug functions local http and onCall
if(process.env.NODE_ENV !== 'production') {
  // firebase.functions().useFunctionsEmulator("http://localhost:5000"); // old
  // firebase.firestore().useEmulator('localhost', 8080)                 // old


  // firebase.app().functions().useEmulator('localhost', 5000);
  // firebase.app().functions(config.region).useEmulator('localhost', 5000);

  window.firebaseDev = firebase.initializeApp(listConfig.musicvoxtomdev.firebase, 'VoxtomDev');
  window.firebaseProd = firebase.initializeApp(listConfig.musicvoxtom.firebase, 'VoxtomProd');

}

const { Timestamp, GeoPoint, FieldValue } = firebase.firestore;
const functions = firebase.app().functions(config.region);
const firestore = firebase.firestore();



async function fcmGetByDocsIds(ref, ids) {
  return fcmGetByPropIds(ref, firebase.firestore.FieldPath.documentId(), ids);
}
async function fcmGetByPropIds(ref, prop, ids, op='in') {
  ref = ref || firestore;
  const result = [];
  for (let i=0;i<ids.length;i = i+30) {
    const ids30 = ids.slice(i, i+30);
    const trs = await ref.where(prop, op, ids30)
      .get();
    for(const trObj of trs.docs) {
      result.push({
        ...trObj.data(),
        id: trObj.id
      });
    }
  }
  return result;
}





export { Timestamp, GeoPoint, FieldValue, firestore, functions, firebase, fcmGetByPropIds, fcmGetByDocsIds};
