"use strict";
// Object.defineProperty(exports, "__esModule", { value: true });
let toExport = {};

toExport.rules = {
  adm: [
    'adm',
    'sell',
    'analy',
    'sup',
  ],
  cli: [
  ],
  comp: [
    'adm',
    'edit',
    'deli',
    'att',
  ],
};

toExport.apiErrorCode = {
  permissionDenied: 'permissionDenied',
  statusError: 'statusError',
  inputError: 'inputError',
  unknown: 'unknown',
  exhaustedAttempts: 'exhaustedAttempts',
  internalConfig: 'internalConfig',
  earlyPublished: 'earlyPublished',
  previously: 'previously',
  error24Hours: 'error24Hours',

  paymentError: 'paymentError',
  bagError: 'bagError',
  paymentInvalidValue: 'paymentInvalidValue',
  notFound: 'notFound',
  emailNotConfig: "emailNotConfig",

  bagEmptyProduct: 'bagEmptyProduct',
  bagEmptyAddress: 'bagEmptyAddress',
  OK: 'OK',

};

// response example: {isOk:false, errorCode: orderErrorCode.unknown, msg:''}


toExport.OrderPaymentMethodType = {
  OnApp: 'OnApp',
  whenReceive: 'whenReceive'
};


toExport.OrderControlStatus = {
  onEdit: 'onEdit',
  waitFinishPayment: 'waitFinishPayment',  // Order não pode ser editada
  paymentComplete: 'paymentComplete',
};

toExport.VerifyStatus = {
  approved: "approved",
  pending: "pending",
  waitApproved: "waitApproved",
};

toExport.bankList = {
  "001": "Banco do Brasil",
  "033": "Santander",
  "104": "Caixa Econômica",
  "237": "Bradesco",
  "341": "Itaú",
  "041": "Banrisul",
  "748": "Sicredi",
  "756": "Sicoob",
  "077": "Inter",
  "070": "BRB",
  "085": "Via Credi",
  "655": "Neon",
  "260": "Nubank",
  "290": "Pagseguro",
  "212": "Banco Original",
  "422": "Safra",
  "746": "Modal",
  "021": "Banestes",
};


toExport.Workspace = {
  COMPANY: 'COMPANY',
  ADMIN: 'ADMIN',
  CLIENT: 'CLIENT',
  UNKNOWN: 'UNKNOWN',
};


toExport.JobState = {
  NOT_STARTED: "NOT_STARTED",
  RUNNING: "RUNNING",
  FINISH: "FINISH",
};


toExport.states = [
  {id:28, code:'--'},
  {id:1, code:'AC'},
  {id:2, code:'AL'},
  {id:3, code:'AP'},
  {id:4, code:'AM'},
  {id:5, code:'BA'},
  {id:6, code:'CE'},
  {id:7, code:'DF'},
  {id:8, code:'ES'},
  {id:9, code:'GO'},
  {id:10, code:'MA'},
  {id:11, code:'MT'},
  {id:12, code:'MS'},
  {id:13, code:'MG'},
  {id:14, code:'PA'},
  {id:15, code:'PB'},
  {id:16, code:'PR'},
  {id:17, code:'PE'},
  {id:18, code:'PI'},
  {id:19, code:'RJ'},
  {id:20, code:'RN'},
  {id:21, code:'RS'},
  {id:22, code:'RO'},
  {id:23, code:'RR'},
  {id:24, code:'SC'},
  {id:25, code:'SE'},
  {id:26, code:'SP'},
  {id:27, code:'TO'},
];


toExport.access = {
  user: 'user',
  admin: 'admin',
};

toExport.accessList = ['user', 'admin'];

toExport.videoType = {
  KVideo: 'KVideo',
  KPocket: 'KPocket',
  KFlash: 'KFlash',
  KYoutube: 'KYoutube'
};

toExport.interactions = {
  KPlaylistFollow: 'KPlaylistFollow',
  KPlaylistCloned: 'KPlaylistCloned',
  KPlaylistMusicAdd: 'KPlaylistMusicAdd',
  KVideoVoted: 'KVideoVoted',
  KUserFollow: 'KUserFollow',
  KVideoComment: 'KVideoComment',
  KVideoMention: 'KVideoMention',
  KVideoShared: 'KVideoShared',
  KEventNew: 'KEventNew',
  KEventChange: 'KEventChange',
  KEventCanceled: 'KEventCanceled',
  KEventReActive: 'KEventReActive',
};

toExport.usersInteractions = {
  KUserFollow: 'KUserFollow',
};

toExport.PublicityType = {
  KRadar: 'KRadar',
  KEvent: 'KEvent',
};

toExport.AnalyticsStatus = {
  KError: 'KError',
  KUpdated: 'KUpdated',
  KUpdating: 'KUpdating',
};

toExport.auth = {
  google: 'google',
  spotify: 'spotify',
  instagram: 'instagram',
  facebook: 'facebook',
};

toExport.ReportStatus = {
  REPORTED: 'REPORTED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
};

toExport.NotificationManualType = {
  NONE: 'NONE',
  USER: 'USER',
  EVENT: 'EVENT'
};

toExport.SendTo = {
  NONE: 'NONE',
  ALL: 'ALL',
  SEARCH: 'SEARCH',
  USERS: 'USERS',
};

toExport.NotificationManualStatus = {
  PENDING: 'PENDING',
  SEND: 'SEND',
  SENDING: 'SENDING',
  ERROR: 'ERROR'
};

toExport.USER_VOXTOM_ID = 'vzSRtVIw3XQ2fjyXoJ4xgmF5uAw1';




module.exports = toExport;

// exports.default = exports;
// if(typeof module!=='undefined') {
//   module.exports = exports;
// }
