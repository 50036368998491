module.exports = {
  format: {
    date: "DD/MM/YYYY",
    dateHour: "DD/MM/YYYY HH:mm",
  },
  login: {
    welcomeDev: "VoxtomDev",
    welcome: "Voxtom",
    access: "Acessar",
    accessCompany: "Acessar",
    errorCompany: "Nenhuma empresa associada a esse email",
    login: "Entrar",
    goReset: "Esqueci minha senha",
    goLogin: "Retornar ao login",
    accessClient: "Acesso Cliente",
    accessAdmin: "Acesso Admin",
    facebook: "Entrar com Facebook",
    google: "Entrar com Google",
    apple: "Entrar com Apple",
    phone: "Entrar com Telefone",
    facebookRegister: "Cadastro com Facebook",
    googleRegister: "Cadastro com Google",
    appleRegister: "Cadastro com Apple",
    phoneRegister: "Cadastro com Telefone",
    emailRegister: "Cadastro com Email",
    getPhoneNumber: "Insira seu número de telefone",
    getPhoneCode: "Insira seu código de verificação enviado ao seu celular",
    resetCompany: "Resetar Senha",
    reset: "Enviar email",
    error: {
      generic: "Houve algum erro ao efetuar login",
      phoneEmpty: "Número de telefone vazio",
      phoneCodeEmpty: "Código de verificação vazio",
      loginTitle: "Erro ao efetuar login",
      changePasswordTitle: 'Erro ao alterar a senha',
      providerTitle: "Erro ao adicionar provider",
      resetNotFound: 'Reset de senha não encontrado',
      accessDenied: 'Acesso negado',
    },
    providerCode: {
      "auth/invalid-email": "Email inválido",
      "auth/user-disabled": "Conta desabilitada",
      "auth/user-not-found": "Usuário não encontrado",
      "auth/wrong-password": "Senha incorreta",
      "auth/credential-already-in-use": "Credencial já utilizada",
      "auth/email-already-exists": "Email já utilizado",
      "auth/too-many-requests": "Muitas tentativas, tente novamente mais tarde",
      "auth/operation-not-allowed": "Tipo de login não configurado, tente outra forma de login",
      "auth/network-request-failed": "Parece que você não está conectado na internet",
      missingEmail: "O email é necessário",
      missingPassword: "A senha é necessária",
      internal: "Erro interno",
      notFound: "Esse usuário não existe"
    },
    addFacebook: "Adicionar Facebook",
    addGoogle: "Adicionar Google",
    addApple: "Adicionar Apple",
    providers: "Métodos de autenticação",
    remove: "Remover",
    providerId: "Provider",
    email: "E-mail",
    uid: "uid",
    changePassword: "Alterar senha",
    addPassword: "Adicionar Senha",
    resetClient: "Resetar senha",
    register: "Registrar",
    registerClient: "Registrar",
    resetPassword: "Resetar senha",
    makeRegister: "Efetuar cadastro",
    title: "Entrar",
    password: "Senha",
    copyright: "© Voxtom",
    choseAnotherPassword: 'Escolha uma nova senha',
    resetSuccess: 'Senha alterada com sucesso',
    userAssociated: 'Usuário existente associado a essa empresa, a senha dele é a senha previamente cadastrada',
    welcomeClient: 'Bem vindo ao Voxtom',
    welcomeAdmin: 'Bem vindo ao Voxtom Admin',
    welcomeClientDev: 'Voxtom, área de desenvolvimento',
    welcomeAdminDev: 'Voxtom Admin, área de desenvolvimento',
    goLoginAdmin: 'Acesso Admin',
    goLoginClient: 'Acesso',
  },
  all: {
    notifications: 'Notificações',
    events: 'Eventos',
    tags: 'Tags',
    reportsEvents: 'Eventos reportados',
    reportsUsers: 'Usuários reportados',
    about: 'Sobre',
    faqs: 'Faq',
    faqsCategories: 'Categorias de Faq',
    access: 'Acessos',
    dashboard: "Dashboard",
    adminUsers: "Usuários admin",
    users: "Usuários",
    publicity: "Publicidade",
    versionNav: "Voxtom",
    genres: "Gêneros",
    goBack: "Voltar",
    configs: "Configurações",
    services: "Serviços",
    logout: "Sair",
    switchDarkTheme: "Modo escuro",
    switchLanguage: "Idioma",
    profile: "Perfil",
    posts: "Box Oficial"
  },
  form: {
    createdBy: 'Criado por',
    events: 'Eventos',
    eventDateBegin: 'Do dia',
    eventDateEnd: 'Até o dia',
    begin: 'início',
    end: 'fim',
    reportedAt: 'Reportado em',
    reportedBy: 'Reportado por',
    all: 'Todos',
    status: 'Status',
    reportStatus: 'Status',
    isBlocked: 'Bloqueado',
    blocked: 'Bloqueado',
    notBlocked: '',
    body: 'Texto',
    date: 'Data',
    birthDateBegin: 'Nascimento de',
    birthDateEnd: 'Nascimento até',
    createdAtBegin: 'Criado de',
    createdAtEnd: 'Criado até',
    views: "Visualizações",
    uniqueViews: "Visualizações únicas",
    hits: "Vídeos",
    selectVideo: "Selecione o vídeo",
    userPlan: "Plano",
    emailAdmin: "Email administrador",
    userAdmin: "Nome administrador",
    tradeName: "Razão social",
    momName: "Nome da mãe",
    hasCPF: "Tem CPF?",
    type: 'Tipo',
    genderText: 'Gênero',
    gender: {
      male: "Masculino",
      female: "Feminino",
      other: "Outro",
    },
    tellTheSex: "Informe o sexo",
    birthDate: "Data de nascimento",
    error: {
      invalidCPF: "CPF  inválido",
      justNumbers: "Digite apenas números",
      required: "Esse campo é requerido",
      minLength: "O campo é muito curto",
      maxLength: "O campo é muito longo",
      update: "Houve algum erro",
      email: "Email inválido",
      image: {
        type: "Tipo de arquivo inválido, esperado: PNG, JPEG",
        upload: "Erro ao fazer ulpload da imagem, tente novamente mais tarde!"
      },
      invalidValue: "Entrada inválida",
      formInvalid: "Campo inválido",
      uploadImage: "Erro ao fazer upload de imagem",
      closeEditor: "Conclua ou cancele o editor antes de proseguir",
      showOnMenu: "Mostrar no menu",
      regionAreaName: "Erro ao obter nome da área da região",
      NeedRegion: "É necessário definir uma região",
      changeOnlyAdmin: "Entre em contato com o suporte para efetuar essa ação",
      passwordRequired: "A senha é necessária",
      emailRequired: "O email é necessário",
      createUser: 'Erro ao criar autenticação do usuário',
      changePassword: 'Erro ao alterar senha do usuário',
      changeEmail: 'Erro ao alterar email do usuário',
      cpfInvalid: 'CPF inválido',
      cpfExist: 'O CPF já existe',
      cpfError: 'Erro ao validar o cpf',
      copiedToClipboard: 'Falha ao copiar para o Clip Board',
      passwordConfirmError: 'As senhas são diferentes',
      passwordTryChange: 'Erro ao tentar alterar a senha',
      uploadError: 'Erro ao fazer upload',
      uploadErrorContinueAsk: 'Deseja salvar o evento sem fazer o upload?',
      isMatrizLocked: 'Só é possível alterar essa flag na criação',
    },
    update: "Atualizar",
    updateSuccess: "Dados salvos",
    email: "Email",
    password: "Senha",
    currentPassword:'Senha antiga',
    newPassword: 'Nova senha',
    passwordConfirmation:'Confirmação da nova senha',
    continue: "Continuar",
    cnpj: "CNPJ",
    cpf: "CPF",
    name: "Nome",
    prevStep: "Etapa Anterior",
    nextStep: "Próxima etapa",
    conclude: "Concluir",
    close: "Fechar",
    cancel: "Cancelar",
    changePrimaryColor: "Alterar cor primária",
    changeSecondaryColor: "Alterar cor secundária",
    emailResetSuccess: "Em instantes você receberá um email para redefinir a sua senha",
    emailResetError: "Houve um erro ao tentar enviar email de redefinição de senha",
    changePassword: "Alterar Senha",
    passwordNew: "Nova Senha",
    passwordOld: "Senha atual",
    address: {
      city: "Cidade",
      complement: "Complemento",
      country: "Pais",
      district: "Bairro",
      number: "Número",
      search: "Buscar Endereço",
      state: "Estado",
      street: "Rua",
      zipcode: "CEP",
      fieldWarning: "Erro ao buscar endereço",
      emptyResult: "Nenhum endereço encontrado"
    },
    contact: "Contato",
    description: "Descrição",
    phone: "Telefone",
    contactPhone: "Telefone para contato",
    delete: "Deletar",
    confirmDelete: "Tem certeza que deseja deletar?",
    edit: "Editar",
    add: "Adicionar",
    permission: "Permissão",
    active: "Ativo",
    inactive: "Inativo",
    isActive: 'Ativo?',
    create: "Criar",
    isLiquid: "É líquido",
    isMandatory: "Obrigatório",
    numberOfChoices: "Máximo de escolhas (0 sem limite)",
    print: "Imprimir",
    showIds: "Mostrar Ids",
    visible: "Visísvel",
    required: "Obrigatório",
    nutritionalUnit: "Unidade de medida",
    showOnMenu: "Mostrar no menu",
    isCash: "É dinheiro?",
    getDeliveryAreaName: "Nome da área de entrega",
    getRegionName: "Nome da área da região",
    isProduct: "Aplicável em produtos",
    isCompany: "Aplicável em empresas",
    isRestrictions: "É Restrição Alimentar",
    uniqueName: "Identificador único",
    slug: "Slug(url)",
    title: "Título",
    subTitle: "Legenda",
    bodyText: "Texto",
    abstract: "Resumo",
    response: "Resposta",
    category: "Categoria",
    approved: "Aprovado",
    isAdvanced: "Edição avançada",
    alert: {
      emailAddSuccess: "Email adicionado com sucesso",
      hasChangesPending: "Há alterações pendentes, Tem certeza que deseja fechar?",
      deleteUser: "Remover o usuário?",
      deleteEvent: "Remover o evento?",
      confirmSenNotification: "Enviar notificações?"
    },
    region: "Região",
    isTest: "Mostrar em teste",
    isNew: "Restaurante novo",
    regionAll: "Todas as Regiões",
    selectUser: "Selecione o usuário",
    selectUsers: "Selecione os usuários",
    selectCompany: "Selecione a empresa",
    selectCompanies: "Selecione as empresas",
    selectProduct: "Selecione o produto",
    selectProducts: "Selecione os produtos",
    link: "Link",
    selectUsersOrderNotifications1: "Usuários notificados em pedidos atrasados em 5 min",
    selectUsersOrderNotifications2: "Usuários notificados em pedidos atrasados em 10 min",
    deliveryHubDenied: "Bloquear delivery nessa região?",
    regionAreaInfo: "Área para BLOQUEAR recebimento de pedidos para ENTREGA",
    search: "Buscar",
    color: "Cor",
    createdAt: "Criado em",
    selectRules: 'Selecione os acessos',
    promptQuestion: "",
    confirmQuestion: "Você tem certeza?",
    confirmQuestionYes: "Sim",
    confirmQuestionCancel: "Cancelar",
    choseNewPassword: "Escolha uma nova senha",
    fixUserAuth: "Corrigir Auth do usuário",
    rules: "Acessos",
    crm: "CRM",
    isAnesthetist: 'É anestesista',
    copiedToClipboard: 'Url copiada para o clipboard',
    accessCompany: "Acessar empresa",
    isMatriz: 'É Matriz? (Utilizado para cooperativa, não pode ser alterado depois de criada a empresa)',
    isFilial: 'É filial?',
    matrizName: 'Empresa matriz',
    next: 'Próximo',
    linkUser: 'Vincular o usuário {user} na empresa {company}',
    removeFromCompany: 'Remover da empresa',
    removeFromCompanyConfirm: 'Tem certeza que deseja desvicular o usuário da empresa?',
    selectAccess: 'Selecione os acessos do usuário nessa empresa',
    addProcedure: 'Adicionar procedimento',
    username: 'Username',
  },
  image: {
    crop: "Crop",
    zoomIn: "Zoom In",
    zoomOut: "Zoom Out",
    rotateLeft: "Girar Esquerda",
    rotateRight: "Girar Direita",
    mirrorY: "Espelhar Y",
    mirrorX: "Espelhar X",
    show: "Mostrar Imagem",
    remove: "Deletar Imagem",
    selectNewFile: "Selecionar um novo arquivo"
  },
  access: {
    title: "Acesso",
    admin: "Administrador",
    user: "Usuário",
  },
  tools: {
    title: "Ferramenta"
  },
  rules: {
    adm: {
      adm: "Administrador",
      sup: "Suporte"
    },
  },
  router: {
    accessDenied: "Acesso Negado",
  },
  emailReset: {
    subject: "Resetar senha",
    head: "Recebemos uma solicitação para redefinir sua senha do Voxtom,\n acesse o link abaixo para definir uma nova senha",
    body: "",
    link: "Resetar senha",
    hello: 'Olá',
  },
  blog: {
    title: "",
    notFound: "Post Não Encontrado"
  },
  page: {
    title: "",
    notFound: "Página Não Encontrado"
  },
  inputError: {
    name: "Preencha o nome",
    companyName: "Preencha o nome da empresa",
    cnpj: "Preencha o cnpj",
    priceRange: "Preencha o intervalo de valor",
    businessType: "Preencha o tipo de negócio",
    completeAddress: "Termine de preencher o endereço",
    completeBank: "Termine de preencher as informações bancárias",
    phone: "Preencha o telefone",
    previousSend: "Enviado anteriormente",
    needCanReceive: "É necessário ter a conta de pagamento aprovada"
  },
  paginate: {
    prev: "Prev",
    next: "Next"
  },
  _404: {
    notFoundHeader: "Página não encontrada, tente recarregar a página.",
    timeout: "A página atingiu o tempo limite durante o carregamento. Tem certeza de que ainda está conectado à Internet?"
  },
  jobs: {
    type: "Tipo",
    name: "Nome",
    arg: "argumentos"
  },
  apiErrorCode: {
    otherUser: "O usuário não tem permissão para alterar a senha de outro.",
    statusError: "Estado inválido",
    permissionDenied: "O usuário não tem permissão para executar essa ação",
    unknown: "Erro desconhecido",
    paymentError: "Erro ao efetuar o pagamento",
    inputError: "Erro nos parâmetros",
    exhaustedAttempts: "Muitas tentativas",
    paymentInvalidValue: "Valor de pagamento inválido",
    notFound: "Não encontrado",
    OK: "Sucesso",
    previously: "Já feito anteriormente"
  },
  FcmComponents: {
    name: 'Nome',
    search: 'Buscar',
    save: 'Salvar',
    conclude: 'Concluir',
    selected: 'Selecionado',
    selecteds: 'Selecionados',
    confirmButton:'Confirmar',
    warn: {
      noSave: 'Você fechou o form sem salvar os dados'
    }
  },
  statesSigla: {
    '--': 'Estrangeiro',
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    DF: 'Distrito Federal',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SE: 'Sergipe',
    SP: 'São Paulo',
    TO: 'Tocantins',
  },
  fcm: {
    errors: {
      fileTypeNotAllowed: 'Typo de Arquivo não permitido',
      fileNotWillBeUpload: 'Não vai ser feito o upload do arquivo por causa de um erro com o arquivo',
      needFolder: 'É necessário definir um folder',
    },
    confirmDelete: 'Você tem certeza que deseja remover esse arquivo?',
    needUploadToServerToSeeInTab: 'É necessário concluir antes de abrir em nova aba',
  },
  PublicityType: {
    KRadar: "Radar",
    KEvent: "Evento",
  },
  publicityRefType: {
    none: "Nenhum",
    link: "Link",
  },
  api: {
    chamadaFooter: 'Baixe o Voxtom GRÁTIS e venha fazer parte da maior virada da música de todos os tempos!',
    soBaixar: 'É SÓ BAIXAR!',
    interprete: "Interprete",
    compositor: "Compositor",
    browseUpgrade: "Você está usando um navegador <strong>antigo</strong>. Por favor <a href=https://browsehappy.com/'>atualize seu navegador</a> para melhorar sua experiência e segurança.",
    home: "Home",
    contato: "Contato",
    studioPlateia: "Conectados",
    studioBastidores: "Conectou-se",
    studioBiografia: "Biografia",
    studioAgenda: "Agenda",
    studioContato: "Contato",
    studioDownloadChamada: "Clique P/ Baixar",
    studioConnections: "Conexões"
  },
  notification: {
    type: {
      title: "Tipo",
      company: "Empresa",
      product: "Produto",
      link: "Link",
      search: "Busca"
    },
    sendToType: {
      title: "Enviar para",
      region: "Região",
      selected: "Usuários selecionados",
      all: "Todos"
    },
    search: {
      search: "Termo da busca"
    },
    follow: {
      body: " se conectou com você",
      title: "Nova conexão"
    },
    markIGo: {
      body: " vai no seu evento",
      title: "Novo participante"
    },
    diclosure: {
      body: " está anunciando o seu evento",
      title: "Novo anunciante"
    }
  },
  interactionsTitle: {
    NEW_FOLLOWER: "NOVA CONEXÃO",

    KEventNew: "NOVO EVENTO",
    KEventChange: "ALTERAÇÃO DO EVENTO",
    KEventCanceled: "CANCELAMENTO DO EVENTO",
    NEW_DISCLOSURE_TO_FOLLOWER: "DIVULGAÇÃO",
    NEW_DISCLOSURE: "DIVULGAÇÃO",

    NEW_I_GO: "EU VOU",
    KEventReActive: "EVENTO REATIVADO",

    KEventPrivateInvite: "CONVITE PARA EVENTO",
  },
  interactionsBody: {
    NEW_FOLLOWER: "@{username} está conectado a você",
    // KPlaylistFollow: "Segue sua playlist!",
    // KPlaylistCloned: "Clonou a sua playlist",
    KEventNew: "@{username} criou um novo evento {eventTitle}.",
    KEventChange: "{eventTitle} foi modificado pelo @{username}.",
    KEventCanceled: "O evento {eventTitle} foi cancelado - motivo: {cancelledReason}.",
    NEW_DISCLOSURE_TO_FOLLOWER: "@{username} está divulgando o evento {eventTitle}.",

    NEW_DISCLOSURE: "@{username} está divulgando o seu evento {eventTitle}.",
    NEW_I_GO: "@{username} confirmou presença no seu evento {eventTitle}.",
    // KEventReActive: "{eventTitle} foi descancelado pelo @{username}."
    KEventReActive: "O evento {eventTitle} foi reativado",

    KEventPrivateInvite: "Você foi convidado para o evento {eventTitle}",
  },
  ReportStatus: {
    REPORTED: 'Pendente',
    ACCEPTED: 'Aceito',
    REJECTED: 'Recusado'
  },
  NotificationManualType: {
    NONE: 'Normal',
    USER: 'Usuário',
    EVENT: 'Evento'
  },
  NotificationManualStatus: {
    PENDING: 'Pendente',
    SEND: 'Enviado',
    SENDING: 'Enviando',
    ERROR: 'Erro'
  },
  SendTo: {
    ALL: 'TODOS',
    SEARCH: 'Busca',
    USERS: 'Usuários',
  }
};



